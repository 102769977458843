import { v4 as uuidv4 } from "uuid";
import { getRandomInt, sortDescending } from "~/shared/utils";
import {
  CreativeComparisonGroup,
  CreativeComparisonGroupResponse,
  CreativeComparisonGroupSuggestion,
  CreativeComparisonGroupSuggestionResponse,
  CreativeComparisonReport,
  CreativeComparisonReportResponse,
  CreativeComparisonView,
  CreativeComparisonViewResponse,
} from "~/types/CreativeComparison";
import { ReportSnapshotResponse, ReportSnapshot } from "~/types/ReportSnapshot";
import { Provider } from "~/types/shared";

export const useCreativeComparison = () => {
  const getMappedCreativeComparisonReportSnapshpts = (
    snapshots: Array<ReportSnapshotResponse<CreativeComparisonReportResponse>>,
  ): Array<ReportSnapshot<CreativeComparisonReport>> => {
    return snapshots.map((snapshot) => {
      return {
        id: snapshot.id,
        uuid: snapshot.uuid,
        type: snapshot.type,
        createdAt: new Date(snapshot.createdAt),
        data: getMappedCreativeComparisonReports([snapshot.data])[0],
        creator: snapshot.creator,
        reportId: snapshot.reportId,
      };
    });
  };

  const getMappedCreativeComparisonReports = (
    reports: Array<CreativeComparisonReportResponse>,
  ): Array<CreativeComparisonReport> => {
    const mappedReports = reports.map((report) => {
      return {
        id: report.id,
        uuid: report.uuid,
        title: report.title,
        timeframe: report.timeframe,
        startDate: report.startDate != null ? new Date(report.startDate) : null,
        endDate: report.endDate != null ? new Date(report.endDate) : null,
        createdBy: report.createdBy,
        createdAt: report.createdAt,
        views: getMappedCreativeComparisonViews(report.views),
        clientId: report.clientId,
        description: report.description,
        meta: report.meta,
        tiktok: report.tiktok,
        youtube: report.youtube,
        folderId: report.folderId,
        fileSortOrder: report.fileSortOrder,
        workspaceId: report.workspaceId,
        selectedProvider: report.selectedProvider,
        selectedViewType: report.selectedViewType,
      };
    });

    mappedReports.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    return mappedReports;
  };

  const getMappedCreativeComparisonViews = (
    views: Array<CreativeComparisonViewResponse>,
  ): Array<CreativeComparisonView> => {
    return views.map((viewRes) => {
      return {
        info: viewRes.info,
        groups: getMappedCreativeComparisonGroups(viewRes.groups),
        pageNumber: viewRes.pageNumber,
        total: viewRes.total,
      };
    });
  };

  const getMappedCreativeComparisonGroups = (
    groups: Array<CreativeComparisonGroupResponse>,
  ): Array<CreativeComparisonGroup> => {
    const { getMappedCreatives } = useCreatives();

    return groups.map((groupRes) => {
      const images =
        groupRes.creatives.length > 0
          ? groupRes.creatives.flatMap((c) =>
              Array.isArray(c.info.images) && c.info.images.length > 0
                ? [c.info.images[0]]
                : [],
            )
          : [];

      const videos =
        groupRes.creatives.length > 0
          ? groupRes.creatives.flatMap((c) =>
              Array.isArray(c.info.videos) && c.info.videos.length > 0
                ? [c.info.videos[0]]
                : [],
            )
          : [];

      const video =
        Array.isArray(videos) && videos.length > 0 ? videos[0] : null;

      const image =
        Array.isArray(images) && images.length > 0 ? images[0] : null;

      return {
        info: {
          ...groupRes.info,
          showNoImagesPlaceholder: images.length <= 0,
          images,
          videos,
          image,
          video,
          sortOrder: groupRes.info.sortOrder ?? 0,
        },
        creatives: getMappedCreatives(groupRes.creatives),
        pageNumber: groupRes.pageNumber,
        total: groupRes.total,
        chart: groupRes.chart,
      };
    });
  };

  const getMappedCreativeComparisonGroupSuggestions = (
    suggestions: Array<CreativeComparisonGroupSuggestionResponse>,
  ): Array<CreativeComparisonGroupSuggestion> => {
    const { getMappedCreativeInfo } = useCreatives();
    return suggestions
      .map((sugg) => {
        return {
          ...sugg,
          ads: getMappedCreativeInfo(sugg.ads),
        };
      })
      .sort((a, b) => sortDescending(a.ads.length, b.ads.length));
  };

  const getActiveView = (
    provider: Provider,
    report: CreativeComparisonReport,
  ): CreativeComparisonView | null => {
    if (report.views.length <= 0) {
      return null;
    }
    return (
      report.views.find((view) => view.info.provider === provider) ??
      report.views[0]
    );
  };

  const emptyGroupTitle = "##empty";

  const getEmptyGroup = (input: {
    idx: number;
    reportId: number;
    viewId: number;
  }): CreativeComparisonGroup => {
    return {
      info: {
        ...input,
        id: 0 - getRandomInt(1, 10000), // Negative ID to avoid collision with real IDs
        uuid: uuidv4(),
        title: emptyGroupTitle + input.idx,
        filter: null,
        createdAt: "",
        description: null,
        video: null,
        image: null,
        showNoImagesPlaceholder: false,
        images: [],
        videos: [],
        selectedCreativeIds: [],
        sortOrder: input.idx,
        primaryMetric: {
          name: "",
          curValue: 0,
          compareValue: 0,
          relPosition: 0,
          absPosition: 0,
        },
        metrics: [],
      },
      creatives: [],
      pageNumber: 0,
      total: 0,
      chart: [],
    };
  };

  const allGroupsNotEmpty = (
    groups: Array<CreativeComparisonGroup>,
  ): boolean => {
    return groups.every((g) => !isEmptyGroup(g));
  };

  const isEmptyGroup = (group: CreativeComparisonGroup): boolean => {
    return group.info.title.startsWith(emptyGroupTitle);
  };

  return {
    getMappedCreativeComparisonReportSnapshpts,
    getMappedCreativeComparisonReports,
    getMappedCreativeComparisonViews,
    getMappedCreativeComparisonGroups,
    getActiveView,
    getMappedCreativeComparisonGroupSuggestions,
    getEmptyGroup,
    emptyGroupTitle,
    allGroupsNotEmpty,
    isEmptyGroup,
  };
};
